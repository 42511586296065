<template>
  <el-card>
    <el-form ref="form" :model="form" label-width="120px" label-position="left">
      <el-form-item label="Banner">
        <el-table :data="form.banners.list" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="desc">
            <template slot-scope="scope">
              <el-input v-model="scope.row.desc" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('banners', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('banners', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('banners')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <el-form-item label="Share Home">
        <el-input v-model="form.share.home.title" size="mini"></el-input>
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="form.share.home.img"></el-image>
          <el-input slot="reference" v-model="form.share.home.img" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('share', 'home')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item label="Share Solution">
        <el-input v-model="form.share.solution.title" size="mini"></el-input>
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="form.share.solution.img"></el-image>
          <el-input slot="reference" v-model="form.share.solution.img" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('share', 'solution')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import {updateOption, getOption} from '@/api/os/admin';
import config from '@/config';

export default {
  data() {
    return {
      dialogVisible: false,
      index: 0,
      type: '',

      form: {
        banners: {
          list: [],
          title: '',
          desc: ''
        },
        share: {
          home: {
            title: '',
            img: ''
          },
          solution: {
            title: '',
            img: ''
          }
        }
      }
    }
  },
  components: {
    selectImage,
  },
  created() {
    this.get()
  },
  methods: {
    mediaUpload(type,index) {
      this.dialogVisible = true
      this.index = index
      this.type = type
    },
    getImages(data) {
      this.dialogVisible = false
      if(this.type == 'share') {
        return this.form[this.type][this.index].img = data
      }
      this.$set(this.form[this.type].list[this.index], 'image', data)
      // this.form.banner[this.index].image = data
    },
    addAttrs(type) {
      if(!this.form[type]) {
        let data = {
          list: [],
          title: '',
          desc: ''
        }

        this.$set(this.form, type, data);
      }

      return this.form[type].list.push({})
    },
    remove(type, index) {
      this.form[type].list.splice(index, 1)
    },
    get() {
      getOption({
        name: config.default.optionName.wx,
      }).then(res => {
        if(res.data.data) {
          this.form = res.data.data
        }
      })
    },
    onSave() {
      updateOption({
        name: config.default.optionName.wx,
        value: this.form
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    }
  }
}
</script>